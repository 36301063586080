import React from "react"
import { Wrapper } from "./style"
import styled from "styled-components"
import { Instagram, Linkedin, Facebook } from "styled-icons/boxicons-logos"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { transparentize } from "polished"

export const InstagramLinkIcon = styled(({...styleProps}) => (  
    <OutboundLink href="https://www.instagram.com/coach_miriampr/">
      <Instagram {...styleProps} />
    </OutboundLink>   
))`
  color: #660000;
  width: 32px;
  height: 32px;
  margin-right: 2em;
`
export const FacebookLinkIcon = styled(({...styleProps}) => (  
    <OutboundLink href="https://www.facebook.com/CoachMiriamPR/">
      <Facebook {...styleProps} />
    </OutboundLink>   
))`
  color: #660000;
  width: 32px;
  height: 32px;
  margin-right: 2em;
` 

export const LinkedInLinkIcon = styled(({...styleProps}) => (  
    <OutboundLink href="http://es.linkedin.com/in/miriamperezrefoyo">
      <Linkedin {...styleProps} />
    </OutboundLink>   
))`
  color: #660000;
  width: 32px;
  height: 32px;
` 

export const Footer = styled(({ ...styleProps }) => {
  return (
    <footer {...styleProps}>
      <Wrapper>
        <div>        
          <FacebookLinkIcon />
          <InstagramLinkIcon /> 
          <LinkedInLinkIcon />          
        </div>  
        © {new Date().getFullYear()} – <b>Miriam Pérez Refoyo Coach</b>                             
      </Wrapper>
    </footer>
  )
})`
  font-size: 0.8rem;
  line-height: 3rem;
  text-align: center;
  height: 3rem;
  background-color: ${props =>
    transparentize(0.97, props.theme.color.foreground)};
  box-shadow: inset 0 1px 0
    ${props => transparentize(0.95, props.theme.color.foreground)};
`
